<template>
  <div class="tenance-page">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-form :model="queryInfo" :inline="true" ref="queryInfo" label-width="85px" class="my-form">
          <el-form-item label="选择时间" prop="startTime">
            <el-date-picker type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="queryInfo.startTime" placeholder="选择时间"></el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <el-form-item prop="endTime">
            <el-date-picker type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="queryInfo.endTime" placeholder="选择时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="queryInfo.status" placeholder="请选择">
              <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-button @click="handleSearch">查询</el-button>
          <el-button class="reset-btn" @click="handleReset">重置</el-button>
        </el-form>
      </div>
    </div>
    <div class="sec-wrapper">
      <div class="left-wrapper">
        <div class="part">
          <textIcon text="问题数量"></textIcon>
          <div class="tenance-cont-bg">
            <ul class="ul-wrapper">
              <li>
                <div class="title">巡检次数</div>
                <div>
                  <div class="count">
                    {{ chartData.inspectionCount ? chartData.inspectionCount : "0" }}
                  </div>
                  <div class="tp tp1">次</div>
                </div>
                <span class="line"></span>
              </li>
              <li>
                <div class="title">问题数量</div>
                <div>
                  <div class="count">{{ chartData.problemCount ? chartData.problemCount : "0" }}</div>
                  <div class="tp tp1">条</div>
                </div>
                <span class="line"></span>
              </li>
              <li>
                <div class="title">未处理</div>
                <div>
                  <div class="count count2">{{ chartData.untreated ? chartData.untreated : "0" }}</div>
                  <div class="tp tp2">条</div>
                </div>
              </li>
              <li>
                <div class="construction-chart" id="constructionTotal"></div>
                <!-- chartData.per?chartData.per:0.0 -->
                <!-- <div class="process-wrapper">
                  <el-progress
                    type="circle"
                    :show-text="false"
                    :percentage="22.22"
                    :stroke-width="10"
                    color="#3280E4"
                    :width="106"
                    :function="funPercent(chartData.per)"
                  ></el-progress>
                  <div class="txt">
                    {{'66'}}
                    <span style="font-size:16px" class="sp">%</span>
                  </div>
                </div>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="part">
          <textIcon text="问题分布"></textIcon>
          <!-- <div class="ques-tit">问题分布</div> -->
          <div v-if="isQuesShow">
            <div class="tenance-cont-bg ques-height">
              <div id="chart-question" class="h270"></div>
            </div>
            <div class="ques-right-wrap">
              <div class="scroll-wrapper">
                <ul>
                  <li v-for="(item, index) in questionList" :key="index">
                    <div class="ques-item">
                      <div class="ico-bg" :style="`background-image: linear-gradient(180deg,${item.color1} 0%,${item.color2} 100%;`"></div>
                      <div class="type-name elli">{{ item.problemType }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="tenance-cont-bg hei" v-else>暂无数据</div>
        </div>
        <div class="part">
          <textIcon text="巡检次数"></textIcon>
          <div class="tenance-cont-bg">
            <div class="trend-wraper" v-if="isTenaanceShow">
              <div class="deviation-trend" id="deviation-trend"></div>
              <div class="charts-name">
                <img src="@/assets/images/tenance/ques-count.png" alt />
                <span class="mar-R20">问题数量</span>
                <img src="@/assets/images/tenance/tenance-count.png" alt />
                <span>巡检次数</span>
              </div>
            </div>
            <div class="tenance-cont-bg hei" v-else>暂无数据</div>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="table-wrapper">
        <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column align="center" prop="finishModifyTime" label="巡检时间"></el-table-column>
          <el-table-column align="center" prop="creators" label="巡检人"></el-table-column>
          <el-table-column align="center" prop="problemCount" label="问题数量"></el-table-column>
          <el-table-column align="center" prop="status" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0" style="color: #d12a36">巡检中</span>
              <span v-else-if="scope.row.status === 1" style="color: #ffa84b">巡检完成</span>
              <span v-else-if="scope.row.status === 2" style="color: #22a358">处理完成</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="id" label="操作">
            <template slot-scope="scope">
              <!-- 1 巡检完成   处理-->
              <!-- 2 处理完成  查看-->
              <!-- 0 巡检中 删除 -->
              <span v-if="scope.row.status !== 0">
                <span @click="dealOptions(scope.row.id, scope.row.status)" class="pointer operate-txt" :class="!myOption ? 'disable_icon' : ''">{{ scope.row.status == 1 ? "处理" : "查看" }}</span>
              </span>
              <span @click="delOptions(scope.row.id)" style="margin-left: 10px" v-if="isToDel === 1" class="pointer operate-txt" :class="!myDel ? 'disable_icon' : ''">删除</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="img-wrap">
              <span class="no-img"></span>
              <span>暂无数据</span>
            </div>
          </template>
        </el-table>

        <common-pagination v-show="tableData.length > 0" :pageSize="queryInfo.pageSize" class="pageMain" :total="total" :currentPage="queryInfo.pageNum" @handleCurrentChange="handleCurrentChange" />
      </div>
    </div>
    <!-- 操作dialog -->
    <div v-if="isDialogVisiable">
      <options-dialog :isDialogVisiableSync.sync="isDialogVisiable" :tenanceIdSync.sync="tenanceId" :titleSync.sync="title" :quesStatusSync.sync="quesStatus" @initTenanceData="initTenanceData"></options-dialog>
    </div>
    <div v-if="isDelDialogViaiable">
      <el-dialog title="删除" :visible.sync="isDelDialogViaiable" width="500px">
        <el-form label-width="180px">
          <el-form-item>
            <h3 style="text-center;color:#ffffff;font-weight:400">确定要删除该条数据吗？</h3>
          </el-form-item>

          <el-form-item class="btns mT20">
            <el-button class="btn back-btn" @click="cancle">取消</el-button>
            <el-button class="btn submit-btn" @click="commitDel">确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import textIcon from "./components/titleIcon.vue";
import CommonPagination from "../../components/Pagination.vue";
import echarts from "echarts";
import optionsDialog from "./components/optionsDialog.vue";
import commonUtils from "../../utils/common";

export default {
  components: {
    textIcon,
    CommonPagination,
    optionsDialog
  },
  data() {
    return {
      loading: true,
      tableData: [],
      total: 0,
      queryInfo: {
        startTime: commonUtils.timeFormate(commonUtils.getLastYear()),
        endTime: commonUtils.timeFormate(new Date()),
        pageNum: 1,
        pageSize: 11,
        status: "",
        projectId: "" // 10008
      },

      statusOptions: [
        {
          name: "全部",
          id: ""
        },

        {
          name: "巡检中",
          id: "0"
        },
        {
          name: "巡检完成",
          id: "1"
        },
        {
          name: "处理完成",
          id: "2"
        }
      ],
      isDialogVisiable: false, // 操作dialog
      isDelDialogViaiable: false, // 删除dialog
      noChartsData: false, // 暂无数据
      isQuesShow: false, // 问题分布
      isTenaanceShow: false, //巡检次数
      isToDel: 0, // 是否可以去删除  超管才可去删除
      tenanceId: 0, // 巡检id
      title: "", //dialog 标题
      quesStatus: "", //,  问题状态
      chartData: {}, // 图标数据
      questionList: [] // 问题分布
    };
  },
  mounted() {
    this.queryInfo.projectId = sessionStorage.getItem("projectId");
    this.isToDel = sessionStorage.getItem("isAdmin");
    this.initTenanceData();
  },
  methods: {
    initTenanceData() {
      this.showChartData();
      this.getTableList();
    },
    funPercent(per) {
      console.log("pers==", per);
    },
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val;
      this.getTableList();
    },
    getTableList() {
      this.$axios.post(`${this.baseURL}base/inspection/list/`, this.queryInfo).then((res) => {
        if (res.data.status === "200") {
          if (res.data.data.records) {
            this.tableData = res.data.data.records;
          } else {
            this.tableData = [];
            this.noChartsData = true;
          }
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.loading = true;
          this.tableData = [];
          this.noChartsData = true;
        }
      });
    },
    handleSearch() {
      this.queryInfo.pageNum = 1;
      this.showChartData();
      this.getTableList();
    },
    handleReset() {
      this.queryInfo.startTime = "";
      this.queryInfo.endTime = "";
      this.queryInfo.status = "";
      this.queryInfo.startTime = commonUtils.timeFormate(commonUtils.getLastYear());
      this.queryInfo.endTime = commonUtils.timeFormate(new Date());
    },
    showChartData() {
      let params = {
        startTime: this.queryInfo.startTime,
        endTime: this.queryInfo.endTime,
        projectId: this.queryInfo.projectId,
        status: this.queryInfo.status
      };
      this.$axios.post(`${this.baseURL}base/inspection/problems`, params).then((res) => {
        // 偏差趋势
        let color = ["#88DAFE", "#1A80EC"];
        let percentNum = "";
        let num = 0;
        if (res.data.data.problemCountVO !== null) {
          this.chartData = res.data.data.problemCountVO;
          percentNum = res.data.data.problemCountVO.per + "%";
          num = res.data.data.problemCountVO.per;
        } else {
          percentNum = "0" + "%";
          num = "0";
        }
        this.$nextTick(() => {
          document.getElementById("constructionTotal").removeAttribute("_echarts_instance_");
          this.$chart1.dashboardRing22("constructionTotal", color[0], color[1], percentNum, num);
        });

        //问题分布
        if (res.data.data.problemDisVO.length !== 0) {
          this.isQuesShow = true;
          this.questionChart(res.data.data.problemDisVO);
        } else {
          this.isQuesShow = false;
        }
        //巡检次数
        if (res.data.data.inspectionCountVO.length !== 0) {
          this.isTenaanceShow = true;
          this.tenanceChart(res.data.data.inspectionCountVO);
        } else {
          this.isTenaanceShow = false;
        }
      });
    },
    //问题分布
    questionChart(list) {
      let quesColorArr = this.quesColorFun();
      let arr2 = list;

      for (let i = 0; i < arr2.length; i++) {
        arr2[i]["color1"] = quesColorArr[i][0].color1;
        arr2[i]["color2"] = quesColorArr[i][1].color2;
      }
      //右侧问题list
      this.questionList = arr2;
      let arr2data = [];
      let arrcolor = this.circularColorFun();
      let total = 0.0;
      for (let j = 0, m = arr2.length; j < m; j++) {
        // total +=parseFloat(arr2[j].weight)
        // total += arr2[j].weight;
        // console.log('total===', total);
        let obj = {
          name: arr2[j].per,
          value: arr2[j].weight,
          label: {
            normal: {
              color: "#fff"
            }
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 1, 1, 0, arrcolor[j])
            }
          }
        };
        arr2data.push({ ...obj });
      }
      this.$nextTick(() => {
        this.$chart1.tenanceBar("chart-question", "", arr2data);
      });
    },
    //巡检次数
    tenanceChart(list) {
      let xData = [],
        y1Data = [],
        y2Data = [];
      for (let i = 0; i < list.length; i++) {
        xData.push(list[i].month + "月");
        y1Data.push(list[i].problemCount);
        y2Data.push(list[i].inspectionCount);
      }

      this.$nextTick(() => {
        document.getElementById("deviation-trend").removeAttribute("_echarts_instance_");

        console.log("xdata", xData, "y1data", y1Data, "y2data", y2Data);
        this.$chart4.trend("deviation-trend", [], xData, y2Data, y1Data);
      });
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    // 圆环颜色
    circularColorFun() {
      return [
        [
          { offset: 0, color: "rgba(91,164,252,0.80)" },
          { offset: 1, color: "rgba(91,164,252,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(103,210,252,0.80)" },
          { offset: 1, color: "rgba(103,182,252,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(91,252,240,0.80)" },
          { offset: 1, color: "rgba(91,234,252,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(66, 252, 150, 0.8)" },
          { offset: 1, color: "rgba(66, 252, 150, 0.2)" }
        ],
        [
          { offset: 0, color: "rgba(112,103,252,0.80)" },
          { offset: 1, color: "rgba(103,115,252,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(91,53,255,0.80)" },
          { offset: 1, color: "rgba(210,103,252,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(165,103,252,0.80)" },
          { offset: 1, color: "rgba(242,103,252,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(162,53,255,0.80)" },
          { offset: 1, color: "rgba(251,103,252,0.20)" }
        ],

        [
          { offset: 0, color: "rgba(252,103,197,0.80)" },
          { offset: 1, color: "rgba(252,103,249,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(217,252,103,0.80)" },
          { offset: 1, color: "rgba(252,225,103,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(255,242,126,0.80)" },
          { offset: 1, color: "rgba(252,240,53,0.200)" }
        ],
        [
          { offset: 0, color: "rgba(252,182,53,0.80)" },
          { offset: 1, color: "rgba(252,182,53,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(255,200,126,0.8)" },
          { offset: 1, color: "rgba(252,149,53,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(252,117,103,0.8)" },
          { offset: 1, color: "rgba(252,117,103,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(254,128,94,0.8)" },
          { offset: 1, color: "rgba(252,155,103,0.20)" }
        ],
        [
          { offset: 0, color: "rgba(254,128,94,0.80)" },
          { offset: 1, color: "rgba(252,155,103,0.2" }
        ],
        [
          { offset: 0, color: "rgba(255,75,75,0.80)" },
          { offset: 1, color: "rgba(252,64,53,0.2)" }
        ]
      ];
    },
    // 右侧问题颜色
    quesColorFun() {
      return [
        [{ color1: "rgba(91,164,252,0.80)" }, { color2: "rgba(91,164,252,0.20)" }],
        [{ color1: "rgba(103,210,252,0.80)" }, { color2: "rgba(103,182,252,0.20)" }],
        [{ color1: "rgba(91,252,240,0.80)" }, { color2: "rgba(91,234,252,0.20)" }],
        [{ color1: "rgba(66,252,150,0.80)" }, { color2: "rgba(66,252,150,0.20)" }],
        [{ color1: "rgba(112,103,252,0.80)" }, { color2: "rgba(103,115,252,0.20)" }],
        [{ color1: "rgba(91,53,255,0.80)" }, { color2: "rgba(210,103,252,0.20)" }],
        [{ color1: "rgba(162,53,255,0.80)" }, { color2: "rgba(251,103,252,0.20)" }],
        [{ color1: "rgba(252,103,197,0.80)" }, { color2: "rgba(252,103,249,0.20)" }],
        [{ color1: "rgba(165,103,252,0.80)" }, { color2: "rgba(242,103,252,0.20)" }],

        [{ color1: "rgba(217,252,103,0.80)" }, { color2: "rgba(252,225,103,0.20)" }],
        [{ color1: "rgba(255,242,126,0.80)" }, { color2: "rgba(252,240,53,0.200)" }],
        [{ color1: "rgba(252,182,53,0.80)" }, { color2: "rgba(252,182,53,0.20)" }],
        [{ color1: "rgba(255,200,126,0.8)" }, { color2: "rgba(252,149,53,0.20)" }],
        [{ color1: "rgba(252,117,103,0.8)" }, { color2: "rgba(252,117,103,0.20)" }],
        [{ color1: "rgba(254,128,94,0.8)" }, { color2: "rgba(252,155,103,0.20)" }],
        [{ color1: "rgba(254,128,94,0.80)" }, { color2: "rgba(252,155,103,0.20)" }],
        [{ color1: "rgba(255,75,75,0.80)" }, { color2: "rgba(252,64,53,0.20)" }]
      ];
    },

    dealOptions(id, status) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      if (status == 1) {
        this.title = "处理";
      } else {
        this.quesStatus = status;
        this.title = "查看";
      }
      this.tenanceId = id;
      this.isDialogVisiable = true;
    },

    delOptions(id) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }
      this.tenanceId = id;
      this.isDelDialogViaiable = true;
    },

    cancle() {
      this.isDelDialogViaiable = false;
    },
    commitDel() {
      this.$axios.delete(`${this.baseURL}base/inspection/del/${this.tenanceId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.$message({
            message: "删除成功！",
            type: "success"
          });
          this.isDelDialogViaiable = false;
          this.getTableList();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.hei {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
::v-deep .el-table__empty-block {
  .img-wrap {
    margin-top: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .no-img {
      background: url("../../assets/images/tenance/no-data.png");
      background-size: 100% 100%;
      width: 84px;
      height: 73px;
      display: block;
    }
  }
}

.common-one-w {
  width: 30vw;
}

.position-rel {
  position: relative;
}

.tenance-page {
  margin-bottom: 50px;
  .search-wrapper {
    justify-content: space-between;
    line-height: 40px;

    .search {
      .el-form--inline .el-form-item {
        margin-bottom: 0;
      }

      span.line {
        margin-right: 10px;
      }

      ::v-deep .el-input .el-input__inner {
        width: 150px;
        height: 32px;
        line-height: 32px;
      }

      ::v-deep .el-date-editor input.el-input__inner {
        width: 100%;
        padding-right: 5px;
      }

      ::v-deep .el-input__prefix,
      .el-input__suffix {
        // position: absolute;
        // top: 0;
        // left: 0;
        // -webkit-transition: all 0.3s;
        // height: 100%;
        // color: #c0c4cc;
        // text-align: right;
      }

      .reset-btn {
        background: none;
        border: 1px solid #2667db;
      }
    }
  }

  .h270 {
    height: 270px;
    margin-left: -150px;
  }

  .sec-wrapper {
    margin-top: 30px;
    margin-left: 28px;
    margin-right: 29px;
    display: flex;
    align-content: center;

    .tenance-cont-bg {
      width: 596px;
      margin-top: 11px;
      background-image: radial-gradient(circle at 50% 53%, #01042c 0%, #0b2d5a 97%);
      border-radius: 12px;
      margin-top: 15px;
      margin-bottom: 15px;

      .trend-wraper {
        position: relative;
        padding-top: 20px;
        padding-bottom: 10px;
        .deviation-trend {
          width: 600px;
          height: 162px;
        }
        .charts-name {
          position: absolute;
          right: 25px;
          top: 25px;
          display: flex;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          align-items: center;

          img {
            margin-right: 7px;
            margin-left: 10px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .ques-height {
      margin-top: 23px;
      margin-bottom: 22px;
    }

    .left-wrapper {
      width: 653px;
      // height: 763px;
      background: #08204c;
      border-radius: 12px;
      padding-left: 29px;
      padding-bottom: 52px;
      margin-right: 24px;

      .part {
        position: relative;
        margin: 24px auto 10px auto;
      }
      .ques-tit {
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        margin-left: 12px;
      }
      .ul-wrapper {
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative;
        li {
          width: 25%;
          float: left;
          text-align: center;
          position: relative;
          .line {
            width: 1px;
            height: 28px;
            border: 1px solid #3a4a7b;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-50%);
          }

          .title {
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 16px;
            color: #cbe1fc;
            margin-top: 30px;
          }

          .count {
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 28px;
            color: #73d0ff;
            line-height: 40px;
            height: 40px;
            display: inline-block;
            text-align: center;
          }

          .count2 {
            color: #d12a36;
          }

          .tp {
            font-weight: 400;
            font-size: 14px;
            display: inline-block;
            vertical-align: bottom;
            font-family: PingFangSC-Regular;
          }

          .tp1 {
            color: #73d0ff;
          }

          .tp2 {
            color: #d12a36;
          }
          .construction-chart {
            display: block;
            height: 106px;
            margin-right: 50px;
          }
        }
        // .process-wrapper {
        //   position: absolute;
        //   right: 0px;
        //   top: -30px;
        //   .txt {
        //     position: absolute;
        //     left: 50%;
        //     top: 50%;
        //     transform: translate(-50%, -50%);
        //     font-size: 30px;
        //     font-weight: 500;
        //     display: flex;
        //     font-family: AppleSyst0pxUIFont;
        //     .sp {
        //       margin-top: 15px;
        //     }
        //   }
        // }
      }

      .ul-wrapper::after {
        clear: both;
        content: "";
        display: block;
      }

      .ques-right-wrap {
        position: absolute;
        right: 80px;
        top: 75px;

        .scroll-wrapper {
          overflow: hidden;
          ul {
            height: 220px;
            overflow-y: auto;
            // background: #19539B;
          }
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgb(255 255 255 / 20%);
            background: #19539b;
          }
        }

        .ques-item {
          height: 30px;
          .ico-bg {
            display: inline-block;
            vertical-align: middle;
            width: 21px;
            height: 16px;
          }
          .type-name {
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 16px;
            color: #cbe1fc;
            margin-left: 8px;
            height: 30px;
            width: 100px;
            line-height: 30px;
            vertical-align: middle;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }

    .table-wrapper {
      flex: 1;
      overflow: hidden;

      .el-table {
        // height: calc(100% - 50px);
        overflow: auto;
      }
    }

    ::v-deep .hui1 {
      background: #091d46 !important;
    }

    ::v-deep .lv1 {
      background: #0c265a !important;
    }

    ::v-deep .el-table td.el-table__cell,
    ::v-deep .el-table th.el-table__cell.is-leaf {
      font-size: 14px;
      text-align: center;
      height: 62px;
    }
  }
  .pageMain {
    margin-top: 15px;
  }
}
</style>
