export default {
  // 年 月 日 时 :hh:分:秒;
  timeFormate(dates) {
    let date = new Date(Date.parse(dates));
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = date.getDate().toString();

    if (month.length == 1) {
      month = '0' + month;
    }
    if (day.length == 1) {
      day = '0' + day;
    }

    let hh = new Date().getHours();
    let mf =
      new Date().getMinutes() < 10
        ? '0' + new Date().getMinutes()
        : new Date().getMinutes();
    let ss =
      new Date().getSeconds() < 10
        ? '0' + new Date().getSeconds()
        : new Date().getSeconds();
    let curTime =
      year + '-' + month + '-' + day + ' ' + hh + ':' + mf + ':' + ss;
    return curTime;
  },
  //last year
  getLastYear: function () {
    var now = new Date();
    var seconds = 60 * 60 * 24 * 365 * 1 * 1000;
    var timestamp = now.getTime();
    var newDate = timestamp - seconds;
    return new Date(newDate);
  },

  //数字转汉字
  toChinesNum(num) {
    let changeNum = [
      '零',
      '一',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
    ];
    let unit = ['', '十', '百', '千', '万'];
    num = parseInt(num);
    let getWan = (temp) => {
      let strArr = temp.toString().split('').reverse();
      let newNum = '';
      let newArr = [];
      strArr.forEach((item, index) => {
        newArr.unshift(
          item === '0' ? changeNum[item] : changeNum[item] + unit[index]
        );
      });
      let numArr = [];
      newArr.forEach((m, n) => {
        if (m !== '零') numArr.push(n);
      });
      if (newArr.length > 1) {
        newArr.forEach((m, n) => {
          if (newArr[newArr.length - 1] === '零') {
            if (n <= numArr[numArr.length - 1]) {
              newNum += m;
            }
          } else {
            newNum += m;
          }
        });
      } else {
        newNum = newArr[0];
      }

      return newNum;
    };
    let overWan = Math.floor(num / 10000);
    let noWan = num % 10000;
    if (noWan.toString().length < 4) {
      noWan = '0' + noWan;
    }
    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
  },
};
