<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isDialogVisiableSync"
      :before-close="beforeClose"
      class="my-dialog"
      top="7vh"
    >
      <div class="wrapper" id="total-height">
        <div class="cont-wrapper">
          <titleIcon text="基础信息"></titleIcon>
          <div class="content">
            <div class="tit">桥梁</div>
            <span class="sec-con">{{ detailData.projectName }}</span>
          </div>
          <div class="content">
            <div class="tit">时间</div>
            <span class="sec-con">{{ detailData.finishModifyTime }}</span>
          </div>
          <div class="content">
            <div class="tit">天气</div>
            <span class="sec-con">{{ detailData.weather }}</span>
          </div>
          <div class="content">
            <div class="tit">巡查人</div>
            <span class="sec-con">{{ detailData.creators }}</span>
          </div>
          <div class="line"></div>
        </div>
        <ul>
          <li v-for="(item, index) in detailList" :key="index">
            <div class="cont-wrapper top">
              <titleIcon :text="`问题${setWord(index + 1)}`"></titleIcon>
              <div class="state" v-if="item.status === 1">已处理</div>
              <div
                class="state"
                v-else-if="item.status === 0"
                style="color: #32c5ff"
                @click="handlelAction(item)"
              >
                <span class="ico"></span>处理
              </div>
              <div class="content">
                <div class="tit">巡检方向</div>
                <span class="sec-con">{{ item.directionIds }}</span>
              </div>
              <div class="content">
                <div class="tit">具体位置</div>
                <span class="sec-con">{{ item.mileagePrefix }}+{{ item.mileageSuffix }}</span>
              </div>
              <div class="content">
                <div class="tit">所属对象</div>
                <span class="sec-con">{{ item.objectIds }}</span>
              </div>
              <div class="content">
                <div class="tit">问题类别</div>
                <span class="sec-con">{{ item.questionTypes }}</span>
              </div>
              <div class="content">
                <div class="tit">故障照片</div>
                <img :src="setImageUrl(item.faultImageId)" alt class="image" />
              </div>
              <div class="content">
                <div class="tit">巡检情况</div>
                <div :class="item.message.length>20?'desc':'desc1'">{{ item.message }}</div>
              </div>
              <div class="line"></div>
              <div class="cont-wrapper top" v-if="item.isClick">
                <titleIcon text="处理情况"></titleIcon>
                <div class="content">
                  <span class="tit">故障照片</span>
                  <div class="common-div">
                    <el-upload
                      class="upload"
                      :headers="{ token }"
                      :before-upload="beforeComLogoUpload"
                      :on-success="onCompLogoSuccess"
                      ref="uploadCom"
                      accept=".jpg, .png"
                      :action="baseURL + 'sys/sysImages/save/warn'"
                      :file-list="fileListAdd"
                      :show-file-list="false"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <div
                      v-for="(item, index) in fileListAdd"
                      :key="index"
                      class="upload-img-wrapper"
                    >
                      <img
                        v-if="item.response.data"
                        :src="setUpLoadImageUrl(item.response.data)"
                        alt
                        class="upload-img"
                      />
                      <div class="del-img" @click="delImg(index)"></div>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <div class="tit">处理措施</div>
                  <el-input
                    v-model="updateInfo.measure"
                    type="textarea"
                    placeholder="请输入相应内容"
                    :rows="3"
                  ></el-input>
                </div>
                <div class="content">
                  <div class="tit">备注</div>
                  <el-input
                    v-model="updateInfo.remark"
                    type="textarea"
                    placeholder="请输入相应内容"
                    :rows="2"
                  ></el-input>
                </div>
                <div class="content">
                  <div class="tit">处理人</div>
                  <span class="loginer">{{ loginerName }}</span>
                </div>
                <div class="commit-wrap">
                  <div class="sure" @click="commit(item)">确定</div>
                  <div class="cancle" @click="cancle(item)">取消</div>
                </div>
                <div class="line"></div>
              </div>
            </div>
            <!-- 查看  显示 -->
            <div class="cont-wrapper top" v-if="item.status === 1">
              <titleIcon text="处理情况"></titleIcon>
              <div class="content">
                <div class="tit">处理照片</div>
                <img :src="setImageUrl(item.sceneImageId)" alt class="image" />
              </div>
              <div class="content">
                <div class="tit">处理措施</div>
                <div :class="item.measure.length>20?'desc':'desc1'">{{ item.measure }}</div>
              </div>
              <div class="content">
                <div class="tit">备注</div>
                <div :class="item.remark.length>20?'desc':'desc1'">{{ item.remark }}</div>
              </div>
              <div class="content">
                <div class="tit">处理人</div>
                <div class="desc1">{{ item.finishModifier }}</div>
              </div>
              <div class="line"></div>
            </div>
          </li>
        </ul>
      </div>

      <div class="content-more" @click="contMore" id="cont-more"></div>
    </el-dialog>
  </div>
</template>

<script>
import titleIcon from "./titleIcon2.vue";
import commonUtils from "../../../utils/common";
export default {
  components: {
    titleIcon
  },
  props: {
    isDialogVisiableSync: {
      type: Boolean,
      default: false
    },
    tenanceIdSync: {
      type: Number,
      default: 0
    },
    titleSync: {
      type: String,
      default: ""
    },
    quesStatusSync: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      token: window.sessionStorage.getItem("token"),
      title: this.titleSync,
      detailData: {}, // 详情数据
      detailList: [], // 详情列表
      isShowHandleWrap: false,
      fileListAdd: [], // image
      limitNum: 10, // 上传最大限制
      measure: "",
      imgSrc: "", // 返回图片地址
      updateInfo: {
        finishModifier: 0, //处理人
        id: 0, // 处理id
        measure: "", //措施
        remark: "", // 备注
        sceneImageId: "" // 图片id
      },
      loginerName: "",
      itemStr: "发顺丰发顺丰发斯蒂芬斯发的方 法22发生的"
    };
  },

  mounted() {
    console.log("itemStr==", this.itemStr.length);
    this.getInitData();
    this.updateInfo.finishModifier = sessionStorage.getItem("userNameId");
    this.loginerName = sessionStorage.getItem("name");
  },
  methods: {
    getInitData() {
      this.$axios
        .get(`${this.baseURL}base/inspection/get/process/${this.tenanceIdSync}`)
        .then(res => {
          if (res.data.status === "200") {
            if (res.data) {
              this.detailData = res.data.data;
              if (res.data.data.list) {
                let list = res.data.data.list;
                list.forEach(item => {
                  item["isClick"] = false;
                });
                this.detailList = res.data.data.list;
              }
            }
          }
        });
    },

    beforeClose() {
      this.$emit("update:isDialogVisiableSync", false);
      this.$emit("initTenanceData");
    },
    //dialog 展开
    contMore() {
      document.getElementById("cont-more").style.display = "none";
      document.getElementById("total-height").style.height = "1200px";
    },
    // 默认进来展示图片
    setImageUrl(url) {
      return `${this.baseURL}sys/sysImages/img/` + url;
    },
    // 上传成功
    onCompLogoSuccess(res, file, fileList) {
      if (res.status == 200) {
        this.fileListAdd = fileList;
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.errMsg);
      }
    },
    beforeComLogoUpload(file) {
      this.operateType = "sysLogo";
      console.log(file.type);
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
      }
      return isCorrectType;
    },
    // 显示上传图片
    setUpLoadImageUrl(id) {
      return `${this.baseURL}sys/sysImages/img/${id}`;
    },
    //del img
    delImg(index) {
      this.fileListAdd.splice(index, 1);
    },
    // 处理操作
    handlelAction(item) {
      item.isClick = true;
      item.status = 2;
    },

    //提交处理修改
    commit(item) {
      this.setimgId();
      this.updateInfo.id = item.id;
      this.$axios
        .post(`${this.baseURL}app/process/deal/process`, this.updateInfo)
        .then(res => {
          if (res.data.status === "200") {
            this.getInitData();
            this.$message({
              message: "处理成功！",
              type: "sucess"
            });
          } else {
            this.cancle();
          }
        });
    },
    //取消
    cancle(item) {
      item.status = 0;
      item.isClick = false;
    },
    //设置上传图片id
    setimgId() {
      let imgId = "";
      this.fileListAdd.forEach(item => {
        imgId += item.response.data + ",";
      });
      this.updateInfo.sceneImageId = imgId.slice(0, imgId.length - 1);
    },
    setWord(num) {
      return commonUtils.toChinesNum(num);
    }
  }
};
</script>

<style lang="scss" scoped>
.common-div {
  display: flex;
  align-items: center;
}
.upload-img-wrapper {
  position: relative;
  .upload-img {
    width: 100px;
    height: 80px;
    margin-left: 30px;
    border-radius: 4px;
    border: 1px solid #124286;
  }
  .del-img {
    cursor: pointer;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 15px;
    height: 15px;
    background: url("../../../assets/images/del@2x.png");
    background-size: 100% 100%;
    display: inline-block;
  }
}
.upload {
  width: 27px;
  height: 27px;
  text-align: center;
  background: #192c52;
  margin-top: 24px;
  margin-left: 10px;
  background: url("../../../assets/images/tenance/upload-img-logo.png");
  background-size: 100% 100%;
  &:hover {
    cursor: pointer;
  }
  ::v-deep .el-upload.el-upload--text {
    width: 100%;
    height: 100%;
    i {
      color: transparent;
    }
  }
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 400px;
  vertical-align: top;
  font-size: 12px;
  margin-left: 20px;
}

::v-deep .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #ffffff;
  background: #0c265a;
  border: 1px solid #0e43a3;
  border-radius: 8px;
  font-size: 12px;
}
::v-deep .el-input .el-input__inner {
  font-family: PingFangSC-Regular;
  font-size: 12px;
}
.wrapper {
  height: 500px;
  overflow-y: auto;
}

.top {
  margin-top: 39px;
}

.cont-wrapper {
  padding: 39px auto 39px 80px;
  position: relative;

  .state {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #22a358;
    position: absolute;
    right: 30px;
    top: 0;
    cursor: pointer;
    .ico {
      width: 15px;
      height: 13px;
      background: url("../../../assets/images/bianji_icon.png");
      background-size: 100% 100%;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .line {
    width: 550px;
    height: 1px;
    opacity: 0.25;
    border: 1px solid #979797;
    margin-top: 30px;
  }

  .image {
    width: 252px;
    height: 172px;
    border-radius: 4px;
    margin-left: 180px;
    margin-top: -20px;
  }

  .content {
    margin-top: 24px;
    margin-left: 30px;
    color: #ffffff;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    .tit {
      width: 60px;
      display: inline-block;
      height: 20px;
      line-height: 20px;
    }

    .sec-con {
      margin-left: 130px;
    }

    .desc1 {
      width: 300px;
      text-indent: 0em;
      letter-spacing: 0.63px;
      margin-left: 180px;
      color: #ffffff;
      font-size: 14px;
      display: block;
      margin-top: -20px;
    }
    .desc {
      width: 300px;
      text-indent: 2em;
      letter-spacing: 0.63px;
      margin-left: 180px;
      color: #ffffff;
      font-size: 14px;
      display: block;
      margin-top: -20px;
    }
    .tttt {
      display: block;
      margin-left: 180px;
      margin-top: -20px;
    }
    .loginer {
      margin-left: 25px;
    }
  }
  .commit-wrap {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.86);
    text-align: center;
    margin-top: 30px;
    .sure {
      display: inline-block;
      width: 88px;
      height: 32px;
      line-height: 30px;
      background: #2667db;
      border: 1px solid #2667db;
      border-radius: 16px;
      cursor: pointer;
    }
    .cancle {
      cursor: pointer;
      margin-left: 16px;
      display: inline-block;
      width: 88px;
      height: 32px;
      line-height: 30px;
      border: 1px solid #2667db;
      border-radius: 16px;
    }
  }
}

.content-more {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 139px;
  opacity: 0.91;
  background-image: linear-gradient(
    180deg,
    rgba(18, 62, 117, 0) 55%,
    #0f4385 100%
  );
}
</style>
